<template>
  <FormContent>
    <label>
      <b v-if="formData.methodDestinationInfo.type==='CARD'">{{ $t('FORM.DP_DESCRIPTION_CARD') }}</b>
      <b v-else-if="formData.methodDestinationInfo.type==='BANK_ACCOUNT'">{{ $t('FORM.DP_DESCRIPTION_BANK') }}</b>
      <b v-else-if="formData.methodDestinationInfo.type==='OTHER'">{{ $t('FORM.DP_DESCRIPTION_OTHER') }}</b>
      <b v-else-if="formData.methodDestinationInfo.type==='PHONE'">{{ $t('FORM.DP_DESCRIPTION_PHONE') }}</b>
      <b v-else>{{ $t('FORM.DP_DESCRIPTION_COMMON') }}</b>
    </label>
    <div v-if="formData.methodDestinationInfo.description">
      <FormRow>
        <FormCol>
          <FormField>
            <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius">
              <input
                :value="formData.methodDestinationInfo.description"
                readonly
                class="card-input__input brand-secondary brand-primary-focus"
              />
              <div class="c-info-icon" @click="copy(formData.methodDestinationInfo.description)">
                <img :src="require(`@/assets/svg/copy.svg`)" alt=""/>
              </div>
            </div>
          </FormField>
        </FormCol>
      </FormRow>
    </div>
    <div v-if="formData.methodDestinationInfo.destination && formData.methodDestinationInfo.type != 'CARD'">
      <FormRow>
        <FormCol>
          <FormField>
            <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius">
                <input
                  :value="formData.methodDestinationInfo.destination"
                  readonly
                  class="card-input__input brand-secondary brand-primary-focus"
                />
                <div class="c-info-icon" @click="copy(formData.methodDestinationInfo.destination)">
                  <img :src="require(`@/assets/svg/copy.svg`)" alt=""/>
                </div>
              </div>
          </FormField>
        </FormCol>
      </FormRow>
    </div>
    <div v-if="formData.methodDestinationInfo.destination && formData.methodDestinationInfo.type == 'CARD'">
      <FormRow>
        <FormCol>
          <FormField>
            <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius">
              <input
                :value="showPan(formData.methodDestinationInfo.destination)"
                readonly
                class="card-input__input brand-secondary brand-primary-focus"
              />
              <div class="c-info-icon" @click="copy(formData.methodDestinationInfo.destination)">
                <img :src="require(`@/assets/svg/copy.svg`)" alt=""/>
              </div>
            </div>
          </FormField>
        </FormCol>
      </FormRow>
    </div>
    <div v-if="formData.methodDestinationInfo.receiverName">
      <FormRow>
        <FormCol>
          <FormField>
            <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius">
              <input
                :value="formData.methodDestinationInfo.receiverName"
                readonly
                class="card-input__input brand-secondary brand-primary-focus"
              />
              <div class="c-info-icon" @click="copy(formData.methodDestinationInfo.receiverName)">
                <img :src="require(`@/assets/svg/copy.svg`)" alt=""/>
              </div>
            </div>
          </FormField>
        </FormCol>
      </FormRow>
    </div>
    <FormRow>
      <FormCol>
        <FormField>
        </FormField>
      </FormCol>
    </FormRow>
    <div v-if="formData.methodDestinationInfo.amount">
      <FormRow>
        <FormCol>
          <label>
            {{ $t('FORM.AMOUNT_DESCRIPTION.WARNING_MESSAGE') }}
            <img :src="require(`@/assets/svg/exclamation_mark.svg`)" alt=""/>
          </label>
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormField>
            <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius">
              <div class="main-info-body"
                   readonly
                   v-html="amountFormatted"
              />
              <div class="c-info-icon" @click="copy(amountForCopy)">
                <img :src="require(`@/assets/svg/copy.svg`)" alt=""/>
              </div>
            </div>
          </FormField>
        </FormCol>
      </FormRow>
    </div>
    <label>
      {{ $t('FORM.INFO_RETURN_MESSAGE') }}
    </label>
  </FormContent>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import DataFormBase from '@/components/form/pay/steps/forms/DataFormBase';
import {IForm} from '@/components/form/pay/steps/forms/IForm';
import Modal from '@/components/common/Modal.vue';
import FormField from '@/components/form/common/form/FormField.vue';
import FormCol from '@/components/form/common/form/layout/FormCol.vue';
import FormRow from '@/components/form/common/form/layout/FormRow.vue';
import FormContent from '@/components/form/common/form/layout/FormContent.vue';
import TextInputFormField from '@/components/form/common/form/fields/TextInputFormField.vue';
import {currencies} from "@/utils/currencies";

@Component(
  {
    components: {
      TextInputFormField,
      FormField,
      FormContent,
      FormRow,
      FormCol,
      Modal
    },
    directives: {}
  })
export default class DirectPaymentDestinationData extends DataFormBase implements IForm {

  constructor() {
    super();
  }

  copy(item: any) {
    window.navigator.clipboard.writeText(item);
  }

  get amountFormatted(): string {
    let symbol = currencies[this.formData.currency];
    let formatted = Intl.NumberFormat(this.$i18n.locale, {
      style: 'currency',
      currency: this.formData.currency == 'USDT' ? 'USD' : this.formData.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: this.formData.currencyScale != null ? this.formData.currencyScale : 2
    }).format(this.formData.methodDestinationInfo.amount);

    if (symbol) {
      formatted = formatted.replace(this.formData.currency, symbol);
    }
    if (this.formData.currency == 'USDT') {
      formatted = formatted.replaceAll(/[^\d.-]/g, '') + ' USDT';
    }
    return formatted;
  }

  get amountForCopy(): string {
    return Intl.NumberFormat(this.$i18n.locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: this.formData.currencyScale != null ? this.formData.currencyScale : 2
    }).format(this.formData.methodDestinationInfo.amount).replace(',', '');
  }

  showPan(value: string) {
    return value.replace(/[^a-z0-9]+/gi, '').replace(/(.{4})/g, '$1 ');
  }
}
</script>

<style lang="scss" scoped>
</style>
