var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormContent",
    [
      _c("div", [
        _c("b", [_vm._v(_vm._s(_vm.$t("FORM.DP_DESCRIPTION_COMMON")))]),
      ]),
      _vm.formData.methodDestinationInfo.multibancoReference
        ? _c(
            "div",
            [
              _c(
                "FormRow",
                [
                  _c(
                    "FormCol",
                    [
                      _c("FormField", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _c("input", {
                              staticClass:
                                "card-input__input brand-secondary brand-primary-focus",
                              attrs: { id: "bankReference", readonly: "" },
                              domProps: {
                                value:
                                  _vm.formData.methodDestinationInfo
                                    .multibancoReference,
                              },
                            }),
                            _c("label", { attrs: { for: "bankReference" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("FORM.DESCRIPTION.REFERENCE")) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "c-info-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(
                                      _vm.formData.methodDestinationInfo
                                        .multibancoReference
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/svg/copy.svg"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.formData.methodDestinationInfo.multibancoEntity
        ? _c(
            "div",
            [
              _c(
                "FormRow",
                [
                  _c(
                    "FormCol",
                    [
                      _c("FormField", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _c("input", {
                              staticClass:
                                "card-input__input brand-secondary brand-primary-focus",
                              attrs: { id: "bankCode", readonly: "" },
                              domProps: {
                                value:
                                  _vm.formData.methodDestinationInfo
                                    .multibancoEntity,
                              },
                            }),
                            _c("label", { attrs: { for: "bankCode" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("FORM.DESCRIPTION.BANK_CODE")) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "c-info-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(
                                      _vm.formData.methodDestinationInfo
                                        .multibancoEntity
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/svg/copy.svg"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("FormRow", [_c("FormCol", [_c("FormField")], 1)], 1),
      _vm.formData.amount
        ? _c(
            "div",
            [
              _c(
                "FormRow",
                [
                  _c("FormCol", [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("FORM.AMOUNT_DESCRIPTION.WARNING_MESSAGE")
                          ) +
                          " "
                      ),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/svg/exclamation_mark.svg"),
                          alt: "",
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "FormRow",
                [
                  _c(
                    "FormCol",
                    [
                      _c("FormField", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _c("input", {
                              staticClass:
                                "card-input__input brand-secondary brand-primary-focus",
                              attrs: { readonly: "", id: "amount" },
                              domProps: { value: _vm.formData.amount },
                            }),
                            _c("label", { attrs: { for: "amount" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("FORM.DESCRIPTION.VALUE")) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "c-info-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(_vm.amountForCopy)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/svg/copy.svg"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("FormRow", [_c("FormCol", [_c("FormField")], 1)], 1),
      _c("label", [
        _vm._v(" " + _vm._s(_vm.$t("FORM.INFO_RETURN_MESSAGE")) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }