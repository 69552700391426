var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "parent",
      staticClass: "input-tel",
      class: [
        {
          "is-focused": _vm.isFocus,
          "is-valid": _vm.valid,
          "has-value": _vm.value,
          "has-error": _vm.error,
          "is-disabled": _vm.disabled,
          "has-hint": _vm.hint,
        },
        _vm.size,
      ],
      on: {
        click: _vm.focusInput,
        mouseenter: function ($event) {
          return _vm.updateHoverState(true)
        },
        mouseleave: function ($event) {
          return _vm.updateHoverState(false)
        },
      },
    },
    [
      _vm.type === "checkbox"
        ? _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue",
                  },
                ],
                ref: "InputTel",
                staticClass: "input-tel__input brand-secondary",
                class: { "no-country-selector": _vm.noCountrySelector },
                attrs: {
                  id: _vm.id,
                  disabled: _vm.disabled,
                  placeholder: _vm.labelValue,
                  required: _vm.required,
                  type: "checkbox",
                },
                domProps: {
                  checked: Array.isArray(_vm.inputValue)
                    ? _vm._i(_vm.inputValue, null) > -1
                    : _vm.inputValue,
                },
                on: {
                  blur: _vm.onBlur,
                  click: function ($event) {
                    return _vm.$emit("click", $event)
                  },
                  focus: _vm.onFocus,
                  keydown: _vm.keyDown,
                  keyup: _vm.keyUp,
                  change: function ($event) {
                    var $$a = _vm.inputValue,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.inputValue = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.inputValue = $$c
                    }
                  },
                },
              },
              "input",
              _vm.$attrs,
              false
            )
          )
        : _vm.type === "radio"
        ? _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue",
                  },
                ],
                ref: "InputTel",
                staticClass: "input-tel__input brand-secondary",
                class: { "no-country-selector": _vm.noCountrySelector },
                attrs: {
                  id: _vm.id,
                  disabled: _vm.disabled,
                  placeholder: _vm.labelValue,
                  required: _vm.required,
                  type: "radio",
                },
                domProps: { checked: _vm._q(_vm.inputValue, null) },
                on: {
                  blur: _vm.onBlur,
                  click: function ($event) {
                    return _vm.$emit("click", $event)
                  },
                  focus: _vm.onFocus,
                  keydown: _vm.keyDown,
                  keyup: _vm.keyUp,
                  change: function ($event) {
                    _vm.inputValue = null
                  },
                },
              },
              "input",
              _vm.$attrs,
              false
            )
          )
        : _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue",
                  },
                ],
                ref: "InputTel",
                staticClass: "input-tel__input brand-secondary",
                class: { "no-country-selector": _vm.noCountrySelector },
                attrs: {
                  id: _vm.id,
                  disabled: _vm.disabled,
                  placeholder: _vm.labelValue,
                  required: _vm.required,
                  type: _vm.type,
                },
                domProps: { value: _vm.inputValue },
                on: {
                  blur: _vm.onBlur,
                  click: function ($event) {
                    return _vm.$emit("click", $event)
                  },
                  focus: _vm.onFocus,
                  keydown: _vm.keyDown,
                  keyup: _vm.keyUp,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.inputValue = $event.target.value
                  },
                },
              },
              "input",
              _vm.$attrs,
              false
            )
          ),
      _c(
        "label",
        {
          ref: "label",
          class: "brand-secondary-hint",
          attrs: { for: _vm.id },
          on: { click: _vm.focusInput },
        },
        [_vm._v(" " + _vm._s(_vm.hintValue) + " ")]
      ),
      _vm.clearable && _vm.inputValue
        ? _c(
            "button",
            {
              staticClass: "input-tel__clear",
              attrs: { tabindex: "-1", title: "clear", type: "button" },
              on: { click: _vm.clear },
            },
            [
              _c("span", { staticClass: "input-tel__clear__effect" }),
              _c("span", [_vm._v(" ✕ ")]),
            ]
          )
        : _vm._e(),
      _vm.loader
        ? _c("div", { staticClass: "input-tel__loader" }, [
            _c("div", { staticClass: "input-tel__loader__progress-bar" }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }