var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
      class: { "brand-error-container": _vm.invalid },
      on: { click: _vm.focusCvvField },
    },
    [
      _vm.showModal && _vm.modalText
        ? _c("Modal", {
            attrs: {
              body: _vm.modalText ? _vm.modalText : "",
              header: _vm.modalHeader,
            },
            on: {
              close: function ($event) {
                return _vm.closeModalWindow()
              },
            },
          })
        : _vm._e(),
      _c("input", {
        directives: [{ name: "number-only", rawName: "v-number-only" }],
        ref: "cardCvv",
        staticClass: "card-input__input brand-secondary brand-primary-focus",
        attrs: {
          id: "cardCvv",
          autocomplete: "cc-csc",
          "data-card-field": "",
          inputmode: "numeric",
          maxlength: "4",
          name: "cvc",
          placeholder: "CVV/CVC",
          type: "password",
        },
        domProps: { value: _vm.inputValue },
        on: {
          focusin: function ($event) {
            return _vm.focusin($event)
          },
          focusout: function ($event) {
            return _vm.focusout($event)
          },
          input: function ($event) {
            return _vm.changeCvv($event.target.value)
          },
        },
      }),
      _c("label", { attrs: { for: "cardCvv" } }, [_vm._v("CVV/CVC")]),
      _c(
        "div",
        {
          staticClass: "c-info-icon",
          on: {
            click: function ($event) {
              return _vm.showModalWindow()
            },
          },
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/svg/details.svg"), alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }