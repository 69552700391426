var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "method-select__label" }, [
        _vm._v(_vm._s(_vm.$t("FORM.SELECT_METHOD"))),
      ]),
      _vm._l(_vm.formData.paymentMethods, function (method) {
        return _c("PaymentMethodButton", {
          key: method.paymentMethodType,
          attrs: {
            type: method.paymentMethodType,
            "force-logo": method.logo,
            "force-title": method.title,
          },
          on: { "method-selected": _vm.selectMethod },
        })
      }),
      _c("FormTimer", {
        attrs: { options: _vm.timerOptions },
        on: { expired: _vm.expired },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }