import { render, staticRenderFns } from "./DirectPaymentServiceTypeButton.vue?vue&type=template&id=5f341234"
import script from "./DirectPaymentServiceTypeButton.vue?vue&type=script&lang=ts"
export * from "./DirectPaymentServiceTypeButton.vue?vue&type=script&lang=ts"
import style0 from "./DirectPaymentServiceTypeButton.vue?vue&type=style&index=0&id=5f341234&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildagent/work/bda939a141d38e33/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f341234')) {
      api.createRecord('5f341234', component.options)
    } else {
      api.reload('5f341234', component.options)
    }
    module.hot.accept("./DirectPaymentServiceTypeButton.vue?vue&type=template&id=5f341234", function () {
      api.rerender('5f341234', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form/pay/steps/types/DirectPaymentServiceTypeButton.vue"
export default component.exports