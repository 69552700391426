<template>
  <div class="card-input__container brand-secondary brand-secondary-fw brand-input-border-radius"
       v-bind:class="{'brand-error-container': invalid}"
       @click="focusOtpField">
    <input
      id="optInput"
      ref="otpInput"
      v-number-only
      :maxlength="minLength || 6"
      :minlength="maxLength || 6"
      :name="inputName || 'pan'"
      :value="inputValue"
      class="card-input__input brand-secondary brand-primary-focus"
      data-card-field
      inputmode="numeric"
      placeholder="Code"
      required
      type="password"
      autocomplete="off"
      @focusin="focusin($event)"
      @focusout="focusout($event)"
      @input="changeOtp($event.target.value)"
    />
  </div>
</template>


<script lang="ts">
import Component from 'vue-class-component';
import {numberOnlyDirective} from '@/components/form/common/form/directives';
import InputFormFieldBase from '@/components/form/common/form/fields/InputFormFieldBase';
import {Prop} from 'vue-property-decorator';

@Component({
  directives: {
    'number-only': numberOnlyDirective
  }
})
export default class OtpInputFormField extends InputFormFieldBase<string> {
  @Prop()
  maxLength!: number;
  @Prop()
  minLength!: number;


  constructor() {
    super();
    this.inputValue = '';
  }

  focusOtpField() {
    (this.$refs.otpInput as any).focus();
  }

  changeOtp(value: string) {
    this.inputValue = value;
    this.validate();
    this.changed({
      value: this.inputValue,
      invalid: this.invalid,
      canFocusNextInput: false
    });
  }

  isInvalid(): boolean {
    return !this.inputValue || this.inputValue.length > (this.maxLength || 6) || this.inputValue.length < (this.minLength || 6);
  }

  getErrorMessage(): string {
    return 'ERROR.ERROR';
  }

}
</script>

<style scoped>

</style>
