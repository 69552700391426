import FormBrandInfo = com.paidora.shared.types.models.form.FormBrandInfo;
import FormStyles = com.paidora.shared.types.models.form.FormStyles;
import {hexToComplimentary, hexToHsl, hexToRgba} from '@/utils/color-transformer-ui';
import {assignDefined} from '@/utils/utils';

export class FormBrandSrv {
  private static brand: FormBrandInfo;
  private static isSecondaryDark: boolean;

  private static defaultStyles: FormStyles = {
    colorBrand: '#8370ff',
    colorPrimary: '#FFFFFF',
    colorSecondary: '#F6F6F6',
    colorBorder: 'transparent',
    colorAccent: '#8370ff',
    colorAccentGradient: <any>null,
    colorError: '#FF4D42',
    borderRadiusButton: 12,
    borderRadiusHeader: 12,
    borderRadiusInput: 12
  };

  static getBrand() {
    return FormBrandSrv.brand || {styles: FormBrandSrv.defaultStyles};
  }

  static applyBrand(brandInfo: FormBrandInfo) {
    FormBrandSrv.brand = brandInfo;
    const favicon: any = document.getElementById('favicon');
    favicon.href = brandInfo.favicon;
    document.title = brandInfo.pageTitle as string;

    this.setStyleBlock(this.generateCss(brandInfo));
  }

  static isBackgroundDark() {
    return this.isSecondaryDark;
  }

  private static setStyleBlock(css: string) {
    let style: any = document.getElementById('brand_styles');
    if (style) {
      style.remove();
    }
    style = document.createElement('style');
    style.id = 'brand_styles';
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    document.head.appendChild(style);
  }

  private static getTextColorForBackground(bgColor: string): string {
    return hexToHsl(bgColor).l >= 50 ? '#000000' : '#ffffff';
  }

  private static generateCss(brandInfo: FormBrandInfo): string {
    const styles = assignDefined(Object.assign({}, FormBrandSrv.defaultStyles), brandInfo.styles);

    const colorBrand = styles.colorBrand;
    const colorTextBrand = this.getTextColorForBackground(colorBrand);
    const colorPrimary = styles.colorPrimary;
    const colorTextPrimary = this.getTextColorForBackground(colorPrimary);
    const colorSecondary = styles.colorSecondary;
    const colorBorder = styles.colorBorder;
    const colorTextSecondary = this.getTextColorForBackground(colorSecondary);
    const colorAccent = styles.colorAccent;
    const colorAccentGradient = styles.colorAccentGradient || hexToComplimentary(colorAccent, 50);
    const colorTextAccent = this.getTextColorForBackground(colorAccent);
    const colorError = styles.colorError;
    const colorTextError = this.getTextColorForBackground(colorError);

    this.isSecondaryDark = hexToHsl(colorSecondary).l < 50;

    // noinspection CssInvalidPropertyValue
    let css = `
        .brand-primary {
           background-color: ${colorPrimary};
           color: ${colorTextPrimary};
           fill: ${colorTextPrimary};
        }
        .brand-primary-border {
          border: 1px solid ${colorTextPrimary};
        }
        .brand-primary-hover:hover {
          background-color: ${colorSecondary};
          color: ${colorTextSecondary};
          fill: ${colorTextSecondary};
        }
        .brand-primary-focus:focus {
          box-shadow: 0px 10px 20px -13px ${hexToRgba(colorTextPrimary, 0.35)};
        }

        .brand-header {
          background-color: ${colorBrand};
          color: ${colorTextBrand};
          fill: ${colorTextBrand};
        }

        .brand-secondary {
          background-color: ${colorSecondary};
          color: ${colorTextSecondary};
          fill: ${colorTextSecondary};
        }

        .brand-secondary-fw {
           border: 1px solid ${colorBorder};
        }

        label {
          color: ${colorTextSecondary};
        }

        .brand-secondary-fw:focus-within {
          border: 2px solid ${colorTextPrimary};
        }

        .brand-secondary-hw {
          border: 2px solid ${colorSecondary};
        }

        .brand-secondary-hw:hover {
          border: 2px solid ${colorTextPrimary};
        }

        .brand-secondary-bb {
          border-bottom: 1px solid ${colorPrimary};
        }

        input.brand-secondary::placeholder,
        input.brand-secondary::-webkit-input-placeholder {
          color: ${colorTextSecondary};
          opacity: 0.5;
        }

        input.brand-secondary:-moz-placeholder {
          color: ${colorTextSecondary};
          opacity: 0.5;
        }

        input.brand-secondary::-moz-placeholder {
          color: ${colorTextSecondary};
          opacity: 0.5;
        }

        input.brand-secondary:-ms-input-placeholder {
          color: ${colorTextSecondary};
          opacity: 0.5;
        }

        input.brand-secondary::-ms-input-placeholder {
          color: ${colorTextSecondary};
          opacity: 0.5;
        }

        .border-text {
          color: ${colorTextPrimary};
          background-color: ${colorPrimary};
        }

        .brand-secondary-hint {
          color: ${colorTextSecondary};
          opacity: 0.5;
        }

        .brand-accent {
          background-color: ${colorAccent};
          color: ${colorTextAccent};
          fill: ${colorTextAccent};
        }

        .brand-accent--invert {
          color: ${colorAccent};
          fill: ${colorAccent};
        }

        .brand-error {
          color: ${colorError};
          fill: ${colorError};
        }

        .brand-error-hint {
          color: ${colorError};
          opacity: 0.5;
        }

        .brand-error-container,.brand-error-container:focus {
           border: 2px solid ${colorError} !important;
        }

        .brand-button-border-radius {
            border-radius: ${styles.borderRadiusButton}px;
        }
        .brand-input-border-radius {
            border-radius: ${styles.borderRadiusInput}px;
        }
        .brand-input-border-radius-bt {
          border-radius: ${styles.borderRadiusInput}px ${styles.borderRadiusInput}px 0 0;
        }
        .brand-input-border-radius-bb {
          border-radius: 0 0 ${styles.borderRadiusInput}px ${styles.borderRadiusInput}px;
        }

        @media screen and (min-width: 460px) {
          .brand-header-border-radius {
            border-radius: ${styles.borderRadiusHeader}px;
          }
        }

        .brand-button {
          background-color: ${colorPrimary};
          color: ${colorTextPrimary};
          border: 2px solid ${colorTextPrimary};
          fill: ${colorTextPrimary};
        }
        .brand-button:hover {
          background-color: ${colorTextPrimary};
          color: ${colorPrimary};
          fill: ${colorPrimary};
        }

        .brand-button-accent {
          background-image: linear-gradient(to right, ${colorAccent} 0%, ${colorAccent}  30%, ${colorAccentGradient}  51%, ${colorAccent}  70%, ${colorAccent}  100%);
          color: ${colorTextAccent};
          fill: ${colorTextAccent};
        }

        .brand-button-accent:not([disabled]):not(.disabled):hover {
          background-position: right center;
        }

        .brand-button-accent:disabled, .brand-button-accent.disabled {
          background-color: ${colorPrimary};
          color: ${colorTextPrimary};
          border: 2px solid ${colorTextPrimary};
          fill: ${colorTextPrimary};
        }

        .brand-secondary input:-webkit-autofill {
          -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
          -webkit-animation-name: autofill;
          -webkit-animation-fill-mode: both;
          background-color: ${colorSecondary} !important;
          color: ${colorTextSecondary} !important;
        }

        .brand-secondary input:-webkit-autofill,
        .brand-secondary input:-webkit-autofill:hover,
        .brand-secondary input:-webkit-autofill:focus,
        .brand-secondary input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${colorSecondary} inset !important;
          -webkit-text-fill-color: ${colorTextSecondary} !important;
        }

        .brand-toast--success {
          background-color: ${colorAccent};
          color: ${colorTextAccent};
        }

        .brand-toast--info {
          background-color: ${colorSecondary};
          color: ${colorTextSecondary};
        }

        .brand-toast--warning {
          background-color: ${colorError};
          color: ${colorTextError};
        }

        .brand-toast--error {
          background-color: ${colorError};
          color: ${colorTextError};
        }

        .brand-toast--default {
          background-color: ${colorSecondary};
          color: ${colorTextSecondary};
        }

        .brand-toast {
          border-radius: ${styles.borderRadiusHeader}px;
          box-shadow: 0 1px 4px ${hexToRgba(colorTextPrimary, 0.12)}, 0 0 6px ${hexToRgba(colorTextPrimary, 0.04)};
        }
      `;
    if (!brandInfo.showProjectLogo) {
      css += `
              .brand-project-logo {
                display: none;
              }
             `;
    }
    if (brandInfo.iframe) {
      css += `
              .brand-iframe {
                display: none;
              }
             `;
    }
    if (!brandInfo.showHeader) {
      css += `
              .brand-header {
                border: none;
              }
              @media screen and (min-width: 460px) {
                  .brand-header {
                    border-radius: ${styles.borderRadiusHeader}px;
                  }
              }
              .brand-header-bottom {
                display: none;
              }
             `;
    }
    if (!brandInfo.showCardIcons) {
      css += `
              .brand-card-icons {
                display: none;
              }
             `;
    }
    if (!brandInfo.showTermsOfService || !brandInfo.termsOfService) {
      css += `
              .brand-terms-of-service {
                display: none;
              }
             `;
    }
    if (!brandInfo.showPoweredBy) {
      css += `
              .brand-powered-by {
                display: none;
              }
             `;
    } else if (brandInfo.showProjectLogo) {
      css += `
              .brand-powered-by {
                margin-top: 0;
              }
             `;
    }
    return css;
  }
}
