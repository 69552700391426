var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
      class: { "brand-error-container": _vm.invalid },
      on: { click: _vm.setPanFocus },
    },
    [
      _c("input", {
        ref: "cardPan",
        staticClass: "card-input__input brand-secondary brand-primary-focus",
        attrs: {
          id: "cardPan",
          maxlength: _vm.cardInputMaxLength,
          name: _vm.inputName || "pan",
          placeholder: _vm.$t("FORM.CARD.NUMBER"),
          autocomplete: "cc-number",
          "data-card-field": "",
          inputmode: "numeric",
          required: "",
          type: "tel",
        },
        domProps: { value: _vm.inputValue },
        on: {
          focusin: function ($event) {
            return _vm.focusin($event)
          },
          focusout: function ($event) {
            return _vm.focusout($event)
          },
          input: function ($event) {
            return _vm.changePan($event)
          },
        },
      }),
      _c("label", { attrs: { for: "cardPan" } }, [
        _vm._v(_vm._s(_vm.$t("FORM.CARD.NUMBER"))),
      ]),
      _vm.cardType
        ? _c("div", { staticClass: "c-card-icon" }, [
            _c("img", {
              attrs: {
                alt: require("@/assets/svg/default-card.svg"),
                src: require("@/assets/cards/" + _vm.cardType + ".svg"),
              },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }