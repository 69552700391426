var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "payment-method-btn brand-secondary brand-secondary-hw brand-button-border-radius",
      on: { click: _vm.methodSelected },
    },
    [
      _c("div", { staticClass: "payment-method-btn__logo-container" }, [
        _vm.forceLogo
          ? _c("img", {
              staticClass: "payment-method-btn__logo",
              attrs: { src: _vm.forceLogo, alt: _vm.logoAltText },
            })
          : _c("img", {
              staticClass: "payment-method-btn__logo",
              attrs: {
                src: require("@/assets/methods/" + _vm.methodLogo),
                alt: _vm.logoAltText,
              },
            }),
      ]),
      _c("div", { staticClass: "payment-method-btn__label" }, [
        _vm._v(" " + _vm._s(_vm.displayTitle) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }