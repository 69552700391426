var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
      class: { "brand-error-container": _vm.invalid },
      on: {
        click: function ($event) {
          return _vm.$refs.numericField.focus()
        },
      },
    },
    [
      _c("input", {
        ref: "numericField",
        staticClass: "card-input__input brand-secondary brand-primary-focus",
        attrs: {
          id: "numericField",
          placeholder: _vm.$t(_vm.label),
          "data-card-field": "",
          min: _vm.min,
          max: _vm.max,
          name: _vm.inputName,
          type: "number",
        },
        domProps: { value: _vm.inputValue },
        on: {
          focusin: function ($event) {
            return _vm.focusin($event)
          },
          focusout: function ($event) {
            return _vm.focusout($event)
          },
          input: function ($event) {
            return _vm.changeInputValue($event.target.value)
          },
        },
      }),
      _c("label", { attrs: { for: "numericField" } }, [
        _vm._v(_vm._s(_vm.$t(_vm.label))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }