var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "card-input__exp card-input__container brand-secondary brand-secondary-fw brand-input-border-radius",
      class: { "brand-error-container": _vm.invalid },
      on: { click: _vm.focusExpirationField },
    },
    [
      _c("input", {
        directives: [{ name: "number-only", rawName: "v-number-only" }],
        ref: "cardMonth",
        staticClass: "brand-secondary",
        attrs: {
          id: "cardMonth",
          placeholder: "MM",
          autocomplete: "cc-exp-month",
          "data-card-field": "",
          inputmode: "numeric",
          maxlength: "2",
          name: "cardMonth",
          pattern: "0?[1-9]|1[012]",
          required: "",
          type: "text",
        },
        domProps: { value: _vm.inputValue.month },
        on: {
          focusin: function ($event) {
            return _vm.focusin($event)
          },
          focusout: function ($event) {
            return _vm.focusout($event)
          },
          input: function ($event) {
            return _vm.changeMonth($event.target.value)
          },
          click: function ($event) {
            $event.stopPropagation()
          },
        },
      }),
      _c("label", { attrs: { for: "cardMonth" } }, [_vm._v("MM/YY")]),
      _c("span", [_vm._v("/")]),
      _c("input", {
        directives: [{ name: "number-only", rawName: "v-number-only" }],
        ref: "cardYear",
        staticClass: "brand-secondary",
        attrs: {
          id: "cardYear",
          placeholder: "YY",
          autocomplete: "cc-exp-year",
          "data-card-field": "",
          inputmode: "numeric",
          maxlength: "2",
          name: "cardYear",
          pattern: "[0-9]{2}",
          required: "",
          type: "text",
        },
        domProps: { value: _vm.inputValue.year },
        on: {
          focusin: function ($event) {
            return _vm.focusin($event)
          },
          focusout: function ($event) {
            return _vm.focusout($event)
          },
          input: function ($event) {
            return _vm.changeYear($event.target.value)
          },
          click: function ($event) {
            $event.stopPropagation()
          },
        },
      }),
      _c("div"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }