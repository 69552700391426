<template>
  <div class="payment-method-btn brand-secondary brand-secondary-hw brand-button-border-radius" @click="methodSelected">
    <div class="payment-method-btn__logo-container">
      <img v-if="forceLogo" class="payment-method-btn__logo" :src="forceLogo" :alt="logoAltText"/>
      <img v-else class="payment-method-btn__logo" :src="require(`@/assets/methods/${methodLogo}`)" :alt="logoAltText" />
    </div>

    <!-- Заголовок -->
    <div class="payment-method-btn__label">
      {{ displayTitle }}
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Emit, Prop} from 'vue-property-decorator';
import {FormBrandSrv} from '@/services/FormBrandSrv';
import PaymentMethodType = com.paidora.billing.types.models.enums.PaymentMethodType;

@Component({})
export default class PaymentMethodButton extends Vue {
  @Prop()
  type!: PaymentMethodType;
  @Prop()
  forceLogo: string | undefined;
  @Prop()
  forceTitle: string | undefined;
  methodLogos: { [method: string]: { alias: string, extension: string } } = {
    CARD: {
      alias: 'card',
      extension: 'svg'
    },
    GIROPAY: {
      alias: 'giropay',
      extension: 'svg'
    },
    MC: {
      alias: 'mc',
      extension: 'svg'
    },
    NEOSURF: {
      alias: 'neosurf',
      extension: 'svg'
    },
    NETELLER: {
      alias: 'neteller',
      extension: 'svg'
    },
    PAY_SAFE_CARD: {
      alias: 'pay_safe_card',
      extension: 'svg'
    },
    PAY_SAFE_CASH: {
      alias: 'pay_safe_cash',
      extension: 'svg'
    },
    DIRECT_PAYMENT: {
      alias: 'direct_payment',
      extension: 'svg'
    },
    QIWI: {
      alias: 'qiwi_wallet',
      extension: 'png'
    },
    RAPID_TRANSFER: {
      alias: 'rapid_transfer',
      extension: 'svg'
    },
    SKRILL: {
      alias: 'skrill',
      extension: 'svg'
    },
    SOFORT: {
      alias: 'sofort',
      extension: 'svg'
    },
    MPAY_S: {
      alias: 'mpay_s',
      extension: 'svg'
    },
    MULTIBANCO: {
      alias: 'multibanco',
      extension: 'svg'
    }
  };

  get isBackgroundDark() {
    return FormBrandSrv.isBackgroundDark();
  }

  get methodLogo() {
    return `${this.type.toLowerCase()}_${this.isBackgroundDark ? 'dark' : 'light'}.${this.methodLogos[this.type].extension}`;
  }

  get displayTitle() {
    if (this.forceTitle) {
      let currentLocale = this.$i18n.locale;
      if(currentLocale) {
        currentLocale = currentLocale.split('-')[0];
      }
      const parts = this.forceTitle.split('|');
      const localePrefix = `${currentLocale}~`;
      let defaultString = '';

      for (let part of parts) {
        if (part.startsWith(`${currentLocale}~`)) {
          return part.substring(localePrefix.length);
        } else if (!part.includes('~') && defaultString === '') {
          defaultString = part;
        }
      }

      return defaultString || this.$t('METHOD.' + this.type);
    } else {
      // Если forceTitle пустой, используем стандартный перевод
      return this.$t('METHOD.' + this.type);
    }
  }

  get logoAltText() {
    return this.forceTitle === null ? this.$t('METHOD.' + this.type) : this.forceTitle;
  }

  @Emit('method-selected')
  methodSelected() {
    return this.methodLogos[this.type].alias;
  }


}
</script>

<style lang="scss">
.payment-method-btn {
  height: 60px;
  font-size: 18px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .payment-method-btn__label {

  }

  .payment-method-btn__logo {
    display: block;
    max-width: 50%;
    max-height: 25px;
    width: auto;
    height: 100%;
  }
}
</style>
