import { render, staticRenderFns } from "./DirectPaymentDestinationData.vue?vue&type=template&id=0dc85e57&scoped=true"
import script from "./DirectPaymentDestinationData.vue?vue&type=script&lang=ts"
export * from "./DirectPaymentDestinationData.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dc85e57",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildagent/work/bda939a141d38e33/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0dc85e57')) {
      api.createRecord('0dc85e57', component.options)
    } else {
      api.reload('0dc85e57', component.options)
    }
    module.hot.accept("./DirectPaymentDestinationData.vue?vue&type=template&id=0dc85e57&scoped=true", function () {
      api.rerender('0dc85e57', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form/pay/steps/forms/direct/DirectPaymentDestinationData.vue"
export default component.exports